<template>
    <section class="trip-total" v-if="status === 'success'">
        <h2 class="sr-only">Данные отчета "Рейсы"</h2>
        <div class="trip-total__base" v-if="modeId === 'base'">
            <div class="trip-total__box box">
                <table class="trip-total__table trip-total__table_striped trip-total__table_hover">
                    <colgroup>
                        <col class="trip-total__table-column trip-total__table-column_title">
                        <col class="trip-total__table-column trip-total__table-column_delivery_duration">
                        <col class="trip-total__table-column trip-total__table-column_value">
                        <col class="trip-total__table-column trip-total__table-column_orders_count">
                        <col class="trip-total__table-column trip-total__table-column_trip_count">
                        <col class="trip-total__table-column trip-total__table-column_orders_avg_in_trip">
                    </colgroup>
                    <thead>
                        <tr class="trip-total__table-row trip-total__table-row_head">
                            <th class="trip-total__table-cell trip-total__table-cell_head trip-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('title')"
                            >
                                <span class="trip-total__table-value">Источник</span>
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'title' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'title' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-total__table-cell trip-total__table-cell_head trip-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery_duration')"
                            >
                                <span class="trip-total__table-value">Среднее время</span>
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'delivery_duration' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'delivery_duration' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-total__table-cell trip-total__table-cell_head trip-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('value')"
                            >
                                <span class="trip-total__table-value">Сумма</span>
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'value' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'value' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-total__table-cell trip-total__table-cell_head trip-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('orders_count')"
                            >
                                <span class="trip-total__table-value">Заказы</span>
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'orders_count' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'orders_count' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-total__table-cell trip-total__table-cell_head trip-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('trip_count')"
                            >
                                <span class="trip-total__table-value">Рейсы</span>
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'trip_count' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'trip_count' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="trip-total__table-cell trip-total__table-cell_head trip-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('orders_avg_in_trip')"
                            >
                                <span class="trip-total__table-value">Заказов на рейс</span>
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'orders_avg_in_trip' && sortings.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="trip-total__table-value trip-total__table-value_icon"
                                    v-if="sortings.field === 'orders_avg_in_trip' && sortings.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="trip-total__table-row trip-total__table-row_body trip-total__table-row_pinned"
                            v-if="reportRestaurantsSorted.length > 1"
                        >
                            <td class="trip-total__table-cell trip-total__table-cell_title">Итого</td>
                            <td class="trip-total__table-cell">
                                {{reportTotal.delivery_duration | durationFormat}}
                            </td>
                            <td class="trip-total__table-cell">
                                {{reportTotal.value | currencyFloatFormat}}
                            </td>
                            <td class="trip-total__table-cell">
                                {{reportTotal.orders_count | intFormat}}
                            </td>
                            <td class="trip-total__table-cell">
                                {{reportTotal.trip_count | intFormat}}
                            </td>
                            <td class="trip-total__table-cell">
                                {{reportTotal.orders_avg_in_trip | floatFormat}}
                            </td>
                        </tr>
                        <tr class="trip-total__table-row trip-total__table-row_body"
                            v-for="restaurant in reportRestaurantsSorted"
                            :key="restaurant.id"
                        >
                            <td class="trip-total__table-cell trip-total__table-cell_title">
                                <router-link class="link link_red"
                                    :to="{ name: 'TripRestaurant', params: { id: restaurant.id } }"
                                    :title="`Смотреть тайминг ресторана ${restaurant.title}`">
                                    {{restaurant.title}}
                                </router-link>
                            </td>
                            <td class="trip-total__table-cell">
                                {{restaurant.total.delivery_duration | durationFormat}}
                            </td>
                            <td class="trip-total__table-cell">
                                {{restaurant.total.value | currencyFloatFormat}}
                            </td>
                            <td class="trip-total__table-cell">
                                {{restaurant.total.orders_count | intFormat}}
                            </td>
                            <td class="trip-total__table-cell">
                                {{restaurant.total.trip_count | intFormat}}
                            </td>
                            <td class="trip-total__table-cell">
                                {{restaurant.total.orders_avg_in_trip | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="trip-total__compare-other-period" v-else-if="modeId === 'compareOtherPeriod' && compareTrip">
            <div class="trip-total__box box">
                <table class="trip-total__table">
                    <caption class="trip-total__table-caption">Итого</caption>
                    <colgroup>
                        <col class="trip-total__table-column trip-total__table-column_title">
                        <col class="trip-total__table-column trip-total__table-column_delivery_duration">
                        <col class="trip-total__table-column trip-total__table-column_value">
                        <col class="trip-total__table-column trip-total__table-column_orders_count">
                        <col class="trip-total__table-column trip-total__table-column_trip_count">
                        <col class="trip-total__table-column trip-total__table-column_orders_avg_in_trip">
                    </colgroup>
                    <thead>
                        <tr class="trip-total__table-row trip-total__table-row_head">
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Источник</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Среднее время</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Сумма</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Заказы</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Рейсы</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Заказов на рейс</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="trip-total__table-row trip-total__table-row_body">
                            <td class="trip-total__table-cell trip-total__table-cell_body trip-total__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{reportTotal.delivery_duration | durationFormat}}
                                </span>
                                <difference class="trip-total__table-value"
                                    :primaryValue="reportTotal.delivery_duration"
                                    :compareValue="compareTotal.delivery_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{reportTotal.value | currencyFloatFormat}}
                                </span>
                                <difference class="trip-total__table-value"
                                    :primaryValue="reportTotal.value"
                                    :compareValue="compareTotal.value"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{reportTotal.orders_count | intFormat}}
                                </span>
                                <difference class="trip-total__table-value"
                                    :primaryValue="reportTotal.orders_count"
                                    :compareValue="compareTotal.orders_count"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{reportTotal.trip_count | intFormat}}
                                </span>
                                <difference class="trip-total__table-value"
                                    :primaryValue="reportTotal.trip_count"
                                    :compareValue="compareTotal.trip_count"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{reportTotal.orders_avg_in_trip | floatFormat}}
                                </span>
                                <difference class="trip-total__table-value"
                                    :primaryValue="reportTotal.orders_avg_in_trip"
                                    :compareValue="compareTotal.orders_avg_in_trip"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="trip-total__table-row trip-total__table-row_body">
                            <td class="trip-total__table-cell trip-total__table-cell_body trip-total__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                {{compareTotal.delivery_duration | durationFormat}}
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                {{compareTotal.value | currencyFloatFormat}}
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                {{compareTotal.orders_count | intFormat}}
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                {{compareTotal.trip_count | intFormat}}
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                {{compareTotal.orders_avg_in_trip | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="trip-total__box box"
                v-for="restaurant in reportTrip.restaurants" :key="restaurant.id"
            >
                <table class="trip-total__table">
                    <caption class="trip-total__table-caption">
                        <router-link class="link link_red"
                            :to="{ name: 'TripRestaurant', params: { id: restaurant.id } }"
                            :title="`Смотреть рейсы по ресторану ${restaurant.title}`">
                            {{restaurant.title}}
                        </router-link>
                    </caption>
                    <colgroup>
                        <col class="trip-total__table-column trip-total__table-column_title">
                        <col class="trip-total__table-column trip-total__table-column_delivery_duration">
                        <col class="trip-total__table-column trip-total__table-column_value">
                        <col class="trip-total__table-column trip-total__table-column_orders_count">
                        <col class="trip-total__table-column trip-total__table-column_trip_count">
                        <col class="trip-total__table-column trip-total__table-column_orders_avg_in_trip">
                    </colgroup>
                    <thead>
                        <tr class="trip-total__table-row trip-total__table-row_head">
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Источник</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Среднее время</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Сумма</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Заказы</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Рейсы</th>
                            <th class="trip-total__table-cell trip-total__table-cell_head" scope="col">Заказов на рейс</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="trip-total__table-row trip-total__table-row_body">
                            <td class="trip-total__table-cell trip-total__table-cell_body trip-total__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{restaurant.total.delivery_duration | durationFormat}}
                                </span>
                                <difference class="trip-total__table-value" v-if="findRestaurant(compareTrip, restaurant.id)"
                                    :primaryValue="restaurant.total.delivery_duration"
                                    :compareValue="findRestaurant(compareTrip, restaurant.id).total.delivery_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{restaurant.total.value | currencyFloatFormat}}
                                </span>
                                <difference class="trip-total__table-value" v-if="findRestaurant(compareTrip, restaurant.id)"
                                    :primaryValue="restaurant.total.value"
                                    :compareValue="findRestaurant(compareTrip, restaurant.id).total.value"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{restaurant.total.orders_count | intFormat}}
                                </span>
                                <difference class="trip-total__table-value" v-if="findRestaurant(compareTrip, restaurant.id)"
                                    :primaryValue="restaurant.total.orders_count"
                                    :compareValue="findRestaurant(compareTrip, restaurant.id).total.orders_count"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{restaurant.total.trip_count | intFormat}}
                                </span>
                                <difference class="trip-total__table-value" v-if="findRestaurant(compareTrip, restaurant.id)"
                                    :primaryValue="restaurant.total.trip_count"
                                    :compareValue="findRestaurant(compareTrip, restaurant.id).total.trip_count"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <span class="trip-total__table-value">
                                    {{restaurant.total.orders_avg_in_trip | floatFormat}}
                                </span>
                                <difference class="trip-total__table-value" v-if="findRestaurant(compareTrip, restaurant.id)"
                                    :primaryValue="restaurant.total.orders_avg_in_trip"
                                    :compareValue="findRestaurant(compareTrip, restaurant.id).total.orders_avg_in_trip"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="trip-total__table-row trip-total__table-row_body">
                            <th class="trip-total__table-cell trip-total__table-cell_body" scope="row">
                                {{compareDaterange | daterangeText}}
                            </th>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <template v-if="findRestaurant(compareTrip, restaurant.id)">
                                    {{findRestaurant(compareTrip, restaurant.id).total.delivery_duration | durationFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <template v-if="findRestaurant(compareTrip, restaurant.id)">
                                    {{findRestaurant(compareTrip, restaurant.id).total.value | currencyFloatFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <template v-if="findRestaurant(compareTrip, restaurant.id)">
                                    {{findRestaurant(compareTrip, restaurant.id).total.orders_count | intFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <template v-if="findRestaurant(compareTrip, restaurant.id)">
                                    {{findRestaurant(compareTrip, restaurant.id).total.trip_count | intFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                            <td class="trip-total__table-cell trip-total__table-cell_body">
                                <template v-if="findRestaurant(compareTrip, restaurant.id)">
                                    {{findRestaurant(compareTrip, restaurant.id).total.orders_avg_in_trip | floatFormat}}
                                </template>
                                <template v-else>нет данных</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapMutations } from "vuex";
    import { daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import Difference from "@/components/Difference";

    export default{
        name: "TripTotal",
        components: {
            Difference
        },
        props: {
            status: {
                type: String,
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                required: true
            },
            modes: {
                type: Array,
                required: true
            },
            modeId: {
                type: String,
                required: true
            },
            reportTrip: {
                required: true
            },
            compareTrip: {
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            isShowRestaurantsFilter: {
                type: Boolean,
                required: true
            },
            userRestaurants: {
                required: true
            }
        },
        data() {
            return {
                sortings: {
                    field: "title",
                    direction: "ASC"
                },
            };
        },
        computed: {
            reportRestaurants() {
                return this.reportTrip?.restaurants;
            },
            reportRestaurantsSorted() {
                return this.reportRestaurants?.sort((restaurant1, restaurant2) => {
                    if (this.sortings.field === "title") {
                        return compareStrings(
                            restaurant1.title,
                            restaurant2.title,
                            this.sortings.direction
                        );
                    }

                    return compareNumbers(
                        restaurant1.total[this.sortings.field],
                        restaurant2.total[this.sortings.field],
                        this.sortings.direction
                    );
                });
            },
            reportTotal() {
                return this.reportTrip?.total;
            },
            compareRestaurants() {
                return this.compareTrip?.restaurants;
            },
            compareRestaurantsSorted() {
                return this.compareRestaurants.sort((restaurant1, restaurant2) => {
                    if (this.sortings.field === "title") {
                        return compareStrings(
                            restaurant1.title,
                            restaurant2.title,
                            this.sortings.direction
                        );
                    }

                    return compareNumbers(
                        restaurant1.total[this.sortings.field],
                        restaurant2.total[this.sortings.field],
                        this.sortings.direction
                    );
                });
            },
            compareTotal() {
                return this.compareTrip?.total;
            },
        },
        methods: {
            ...mapMutations([
                "toggleReportTotalLink",
                "toggleRestaurantsFilter"
            ]),
            findRestaurant(trip, restaurantId) {
                return trip?.restaurants?.find(({ id }) => id === restaurantId);
            },
            setSorting(field) {
                if (this.sortings.field === field) {
                    this.sortings.direction = this.sortings.direction === "ASC" ? "DESC" : "ASC";
                } else {
                    this.sortings.field = field
                }
            }
        },
        filters: {
            daterangeText
        },
        created() {
            if (this.userRestaurants?.ids?.length === 1) {
                this.$router.push({
                    name: "TripRestaurant",
                    params:{
                        id: this.userRestaurants?.ids[0]
                    }
                });
            } else {
                if (this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(false);
                    this.toggleRestaurantsFilter(true);
                }
            }
        }
    }
</script>

<style lang="scss">
    .trip-total__box {
        padding: 16px;
        overflow-x: auto;

        & + & {
            margin-top: 15px;
        }
    }
    .trip-total__table {
        width: 100%;
    }
    .trip-total__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .trip-total__table-column {
        &_title {
            width: 24%;
        }
        &_delivery_duration {
            width: 16%;
        }
        &_value {
            width: 20%;
        }
        &_orders_count {
            width: 12%;
        }
        &_trip_count {
            width: 12%;
        }
        &_orders_avg_in_trip {
            width: 16%;
        }
    }
    .trip-total__table-row {
        &_body {
            .trip-total__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .trip-total__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .trip-total__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_head {
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }
    }
    .trip-total__table-value {
        line-height: 1;
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
</style>
